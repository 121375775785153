nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    padding: 5px 80px;
    box-shadow: 0 5px rgb(0,0,0,0.06);
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar li {
    list-style: none;
    padding: 20px;
    position: relative;
}

#navbar li a{
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: #000000;
    transition: 0.3s ease-in-out;
    font-family: "Roboto";
}

#navbar li a:hover,
#navbar li a.active {
    color: #54A4C9;
}


#navbar li a:hover::after,
#navbar li a.active::after {
    content: "";
    width: 30%;
    height: 2px;
    background: #54A4C9;
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mobile {
    display: none;
}

#mobile i {
    color: #000000;
}

.logoicon {
    height: 100px;
}

.logoicon_mini {
    height: 32px;
    margin-left: 7px;
}

@media screen and (max-width:769px) {
    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 90px;
        right: -300px;
        width: 300px;
        height: 100vh;
        background: #2a3239;
        box-shadow: 0, 40px, 60px, rgba(0,0,0,0.1);
        padding: 40px, 0, 0, 10px;
        margin-top: -2px;
        transition: 0.3s ease-in-out;
    }

    #navbar.active {
        right: 0;
    }

    #navbar li {
        margin-bottom: 25px;
    }

    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }

    .logoicon {
        margin-left: -70px;
        height: 100px;
    }
}



.info_head {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
}

.li_head {
    list-style: none;
}

.li_head a {
    font-family: "Roboto";
    color: #54A4C9;
    font-size: 15px;
    font-weight: bold;
}

.li_head a span {
    padding-left: 11px;
}