
.QuienesSomos {
    background-image: linear-gradient(180deg, #00B9FF 0%, #2957F2 100%);
    color: #fff;
    border-radius: 30px;
    margin-top: 30px;
}

.QuienesSomos div {
    margin-top: 20px;

}

.QuienesSomos div h2 {
    font-family: "Roboto";
    margin-top: 50px;
}

.QuienesSomos div p {
    font-family: "Roboto";
    font-weight: 400;
    text-align: justify;
}

.regilla_centrada {
    text-align: center;
}

.imageQuienesSomos {
    height: 200px;
}

@media screen and (max-width:769px) {

    .QuienesSomos div {
        margin-top: 0px;
        text-align: center;
    }

    .QuienesSomos div h2 {
        font-family: "Roboto";
        margin-top: 0px;
    }

    .imageQuienesSomos {
        height: 110px;
    }
}


