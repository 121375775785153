
.TiposPolizaSecc {
    margin-bottom: 70px;
    margin-top: 30px;
    
  }

.HowLearn__title {
    text-align: center;
    margin-bottom: 60px;
}

.HowLearn__title h2 {
    font-weight: 300;
    letter-spacing: 2px;
    display: block;
    font-family: "Roboto";
    font-size: 2.4em;
  }

  .HowLearn__title h2 strong {
    display: block;
    font-family: "Roboto";
    color: #0762d9;
    font-size: 1.2em;
    margin-top: 10px;
  }

  .HowLearn__card {
    border: 1px solid gainsboro;
    background: linear-gradient(180deg, #00B9FF 0%, #2957F2 100%);
    text-align: center;
    color: white;
    border-radius: 30px;
    padding: 0px 15px 20px 15px;
    height: 100%;
    transition: all 0.3s ease 0s;
    
  }

  .HowLearn__card_row {
    margin-top: 50px;      
  }

  @media (min-width: 767px) {
    .HowLearn__card_row {
      margin-top: 5px;      
    }
  }

  .HowLearn__card img {
    width: 125px;
    margin-top: -35px;
  }

  .HowLearn__card h4 {
    font-family: "Ubuntu", Sans-serif;
    font-size: 2.1em;
    font-weight: 600;
    margin-top: 15px;
  }

  .HowLearn__card p {
    font-family: "Roboto";
  }

