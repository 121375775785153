
.PrincipalSpace {
    margin-bottom: 0px;
    margin-top: 0px;
    /*background-image: url('../../images/family-legal-protection-3.jpg');*/
    background-image: url('../../images/banner.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 490px;
    width: 100%;
    left: 0;
    
  }

.PrincipalSpace div {
    text-align: center;

}

.PrincipalSpace div h1 {
    color: blue;
    font-family: "Roboto";
    font-size: 45px;
    font-weight: bold;
    line-height: 1.2em;
    margin-top: 270px;
}
