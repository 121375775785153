
.Under {
    background-color: #080b28;
    padding: 50px 0px 50px 0px;
    margin-top: 1100px;
  }

  .Under__Left {
    padding: 0px;
    list-style-type: none;
  }

  .Under__Center {
    text-align: center;
  }

  .Under__Center a img {
    width: 100px;
  }

  .Under__Right {
    text-align: right;
  }

  .Under__Left a {
    color: white;
  }

  .Under__Right h4 {
    color: white;
    padding-bottom: 30px;
    font-family: "Lato", Sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .Under__Right li {
    display: inline-block;
  }

  .Under__Right i {
    color: white;
    border-radius: 10%;
    padding: 9px 12px;
  }

  .Under__Right i.fa-facebook-f {
    background-color: #3b5998;
  }

  .Under__Right i.fa-whatsapp {
    background-color: #25d366;
  }

  .Under__Right i.fa-youtube {
    background-color: #cd201f;
  }
  
  @media (min-width: 767px) {
    .Under {
      background-color: #080b28;
      padding: 50px 0px 50px 0px;
      margin-top: 0px;
    }
  }

  .button_aviso {
    background-color: 'rgba(52, 52, 52, 0.8)';
  }
