
.Documentation {
    margin-bottom: 70px;
    margin-top: 20px;
    
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 750px;
    width: 100%;
    left: 0;
    
}

.ChoosePlan__title h2 {
    font-weight: 900;
    line-height: 1.2em;
    font-family: "Ubuntu", Sans-serif;
    font-size: 1.8rem;
    color: #005BB9;
    text-transform: none;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .ChoosePlan__card {
    text-align: center;
    padding: 11px 20px 11px 20px;
    
  }

  .ChoosePlan__card_in {
    border: 1px solid gainsboro;
    background: #FFFFFF;
    text-align: center;
    color: white;
    width: 100%;
    padding: 11px 30px 11px 30px;
    transition: all 0.3s ease 0s;
    border-style: solid;
    border-color: #005BB9;
    border-width: 10px;
  }

  .ChoosePlan__card_in h3 {
    font-family: "Ubuntu", Sans-serif;
    font-size: 31px;
    color: #201E25;
    font-weight: 600;
  }


  .ChoosePlan__card h4 {
    font-family: "Ubuntu", Sans-serif;
    font-size: 13px;
    color: #005BB9;
    margin-bottom: 50px;
  }

  .ChoosePlan__lu {
    font-family: "Ubuntu", Sans-serif;
    font-size: 13px;
    color: #201E25;
    margin-bottom: 0px;
    list-style-type: none;
    padding-left: 0px;
  }

  .ChoosePlan__lu li div i {
    color: #005BB9;
    margin-right: 10px;
    font-size: 1.3em;
    margin-top: 20px;
  }

  .ChoosePlan__lu_li_div {
    border-bottom: 1px solid #FFC107 !important;
    padding-bottom: 20px;
  }

  @media (min-width: 767px) {
    .ChoosePlan__title h2 {
      font-weight: 900;
      line-height: 1.2em;
      font-family: "Ubuntu", Sans-serif;
      font-size: 3.8rem;
      color: #005BB9;
      text-transform: none;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }


  