
.tabla_precios {
    text-align: center;
    font-family: "Roboto";
    font-size: 20px;
}

.Precios__title {
    text-align: center;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-size: 60px;
}

.Precios_border_cell1 {
    border: 1px solid black;
    border-top: 1px solid black;
}

.Precios_border_cell2 {
    border: none;
    border-bottom: 1px solid black;
}

.Precios_border_cell3 {
    color: white;
    background-color: #2957F2 !important;
}

.Precios_detalle {
    color: black;
    font-weight: bold;
}

.PreciosSecc {
    margin-bottom: 70px;
    margin-top: 30px;
}
